@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;

// :root {
//   /* --main-color: #fca311; */
//   --color-primary: v.$color-primary;
//   --color-primary-dark: #45a29e;
//   --color-primary-light: #c2fef9;

//   --color-secondary: #c5c6c7;

//   --color-neutral: #1f2833;
//   --color-neutral-dark: #0b0c10;

//   --main-color: v.$color-primary;
//   --main-color-med: #222629;
//   --main-color-light: #b197fc;
//   --grey-color-0: #f8f9fa;
//   --grey-color-1: #f1f3f5;
//   --grey-color-2: #e9ecef;
//   --grey-color-3: #dee2e6;
//   --grey-color-4: #ced4da;
//   --grey-color-5: #adb5bd;
//   --grey-color-6: #868e96;
//   --grey-color-7: #495057;
//   --grey-color-8: #343a40;
//   --grey-color-9: #212529;
//   --tooltip-margin: 30px;
//   --tooltip-arrow-size: 6px;
//   --tooltip-text-color: var(--grey-color-9);
//   --tooltip-background-color: var(--grey-color-1);
// }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: v.$text-color-dark;
  overflow-x: hidden;
  box-sizing: border-box;
}
.background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

.background-image {
  object-fit: cover;
  z-index: -100;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
}

h3 {
  font-size: 2.25rem;
  font-weight: 600;
}
input:focus,
textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#__next {
  position: relative;
}

.center {
  margin: auto;
}

.hero__container {
  @include m.flexCenter();
  background-color: v.$color-primary;
  min-height: 100vh;
  height: 100%;
}

.hero {
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  text-align: center;
  @include m.flexCenter(column);
  @include m.mediaDesktop {
    height: 70vh;
    padding: 0;
    @include m.flexCenter();
    justify-content: space-evenly;
  }
}

.hero-title {
  @include m.flexCenter(column);
  // margin-top: 3rem;
  @include m.mediaDesktop() {
    margin-top: 8rem;
    padding: 1rem 1rem 1rem 8rem;
    width: 50%;
    max-width: 70rem;
    color: v.$color-primary-light;
  }

  & h3 {
    font-size: 3rem;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }
}

.hero-title h2 {
  font-size: 5.3rem;
  line-height: 1.4;
}

.hero-logo {
  @include m.flexCenter();
  padding: 4em;
  margin-top: 2rem;
  @include m.mediaDesktop() {
    width: 50%;
  }
  height: 100%;
}

.hero-logo__image-container {
  width: 100%;
  @include m.flexCenter();
  height: 100%;

  position: relative;

  @include m.mediaDesktop() {
    max-width: 30vw;
  }
  // height: fit-content;
}

.hero h1 {
  font-size: 7rem;
  margin: 1rem 0 3rem;
}
.hero p {
  font-size: 2rem;
  line-height: 1.5;
}

.hero__button-container {
  @include m.flexCenter();
  gap: 2rem;
  margin-top: 3rem;

  & button {
    padding: 0.75rem 4rem;
    background-color: v.$color-secondary;
    border-radius: 5px;
    border: 2px solid v.$color-secondary;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    color: v.$color-primary;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      color: v.$color-primary;
      background-color: v.$color-neutral;
      border-color: v.$color-neutral;
      transform: scale(1.04);
      transition: all 0.2s ease;
    }
  }
}

.logo-image {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: v.$color-neutral-dark;
  padding: 2rem 0;
}

.title {
  margin: 1rem 0 1rem 0;
  text-align: center;
  color: v.$grey-color-8;
}

.post-card__image {
  position: relative;
  aspect-ratio: 2/1;
  width: 100%;
  border-radius: 5px 5px 0 0;
  border: none;
}

.post-card__title {
  color: v.$grey-color-8;
}

.post-card__text {
  color: v.$grey-color-8;
}
.post-card__small {
  font-size: 1.1rem;
  color: v.$grey-color-8;
  margin: 0.5rem 0 0.5rem;
}

.post-card__lower {
  margin: 1rem 1rem 6rem;

  overflow: hidden;
  & a:last-child {
    position: absolute;
    bottom: 0rem;
    right: 50%;
    left: 50%;
    width: 12rem;
    text-align: center;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    border: 1px solid v.$color-primary;
    color: v.$text-color-light;
    background-color: v.$color-primary;
    transform: translate(-50%, -50%);
    &:hover {
      background-color: v.$grey-color-1;
      color: v.$color-primary;
    }
  }
}

.post__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: v.$grey-color-1;
  @include m.mediaDesktop {
  }
}

.post__title h1 {
  font-size: 2.5rem;
  /* color: var(--grey-color-8); */
}

// @media (min-width: 800px) {
//   // .post__container {
//   //   width: 800px;
//   // }
//   .post__title h1 {
//   }
// }

.post__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  height: 52rem;
  width: 100%;
  background-color: v.$transparent;
  @include m.mediaPhone {
    height: 15vh;
    min-height: 12rem;
  }
  & h1 {
    @include m.mediaDesktop {
      font-size: 5rem;
    }
  }
}

.post__title h1 {
  margin-top: 3rem;
  max-width: 1000px;
}

.post__info {
  margin: 1em;

  display: flex;
  gap: 5rem;
  font-size: 1.4rem;
}

.post__image-container {
  position: relative;
  margin-top: -30rem;
  max-width: 1000px;
  max-height: 500px;
  min-width: 80vw;
  min-height: calc(80vw / 2);
  @include m.mediaPhone() {
    margin-top: 0;
    min-width: 100vw;
    min-height: calc(100vw / 2);
  }
}

.post__body {
  color: v.$text-color-dark;
  margin: 3rem 0 3rem;
  padding: 0 1rem;
  @include m.mediaDesktop {
    max-width: 80rem;
  }
}

.post__body-html {
  & img {
    width: 100%;
  }

  & h3 {
    margin-top: 3.5rem;
  }
}

.post__body-html p {
  // color: v.$text-color-dark;
  margin: 1.5rem 0 1.5rem 0;
  line-height: 1.5;
}

.list {
  display: grid;
  gap: 1em;
  margin-bottom: 1em;
}

@media (min-width: 1200px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

.list button {
  margin-top: 1em;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: v.$color-primary;
  border: none;
  color: v.$text-color-light;
}

.list button:hover,
.list button:focus {
  background-color: v.$color-primary-dark;
  color: v.$text-color-dark;
  /* border: solid 1px var(--color-primary); */
}

.active {
  position: relative;
}
.active::after {
  content: "";
  /* border: 1px solid #be3939; */
  position: absolute;
  height: 4px;
  background: v.$color-secondary;
  bottom: -4px;
  border-radius: 6px;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
}

.contact-hero__section {
  padding: 2rem 0;
  @include m.flexCenter();
  @include m.mediaDesktop() {
    height: 30%;
  }
}

.contact-hero {
  & h3 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}

.contact-hero .contact-hero {
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
}

.contact-hero h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  color: v.$color-primary;
}

@media (min-width: 800px) {
  .contact-hero h1 {
    font-size: 4rem;
  }

  .contact-hero p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.contact-hero p {
  text-align: center;
  margin-bottom: 1rem;

  color: v.$grey-color-1;
}

.contact-form__container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: v.$color-neutral-dark;
  padding: 3rem 0;
  min-height: 70vh;
}

.about-content__container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 10rem;
  max-width: 800px;

  & h2 {
    margin-bottom: 2rem;
  }
  & h3 {
    margin: 2rem 0;
  }
}

.trading-plan__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1rem auto 1rem auto;
  border-radius: 10px;
  background-color: v.$grey-color-9;
  padding: 1rem;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.24);
  color: v.$grey-color-5;
}

@media (min-width: 800px) {
  .trading-plan__container {
    max-width: 800px;
  }
}

.trading-plan__grid {
  display: grid;
  gap: 1rem;
}

.tool-card__container {
  @include m.flexCenter();
  padding: 2rem 0 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  background-color: v.$color-primary-dark;
  @include m.mediaDesktop() {
    padding: 15rem 3rem;
  }
}

.terms {
  background-color: v.$grey-color-0;
  height: 100%;

  padding: 3rem;
  color: v.$text-color-dark;

  & p {
    max-width: 85rem;
  }
}

.terms__wrapper {
  width: 100%;
  @include m.flexCenter(column);
  background-color: v.$grey-color-0;
}

.terms__container {
  @include m.flexCenter(column);
}

.terms__heading {
  margin: 2rem;
  @include m.flexCenter(column);
  gap: 3rem;
  & h5 {
    font-size: 4.8rem;
  }
}

.beta-test-disclaimer {
  @include m.flexCenter(column);

  padding: 2rem;
  margin: 1rem;

  & h3 {
    margin: 1rem;
  }
  & p {
    max-width: 80rem;
  }
}

.beta-test__button-container {
  @include m.flexCenter();
  gap: 1rem;
  margin: 1rem;
  & button,
  a {
    padding: 1rem;
    border-radius: 5px;
    background-color: v.$color-primary;
    border: none;
    &:hover {
      background-color: v.$color-primary-light;
    }
  }
}

.contact-form__modal {
  padding: 2rem;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  color: v.$color-primary;
  background-color: v.$grey-color-2;
  text-align: center;

  & h1 {
    font-size: 4rem;
  }
}
