@use "../../styles/abstracts/variables" as v;

.ham {
  width: 4.5rem;
  margin: auto 1rem auto;
  right: 2rem;
  height: 3.8rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .ham {
    display: none;
  }
}
.topBar,
.midBar,
.bottomBar,
.topBarOpen,
.midBarOpen,
.bottomBarOpen {
  height: 5px;
  background-color: v.$hamburger;
  border-radius: 5px;
  transform-origin: left;
  margin: 3px 0;
  transition: all 0.5s ease-in-out;
}
.topBar,
.bottomBar,
.topBarOpen,
.bottomBarOpen {
  width: 3.8rem;
}

.midBar,
.midBarOpen {
  width: 3rem;
}

.topBarOpen {
  transform: rotate(45deg);
}

.midBarOpen {
  transform: translateX(-1rem);
  transition: all 0.01s ease-out;
  opacity: 0;
}

.bottomBarOpen {
  transform: rotate(-45deg);
}
