@use "../../styles/abstracts/mixins" as m;
@use "../../styles/abstracts/variables" as v;

.menu,
.menuHidden {
  height: 100vh;
  min-height: 50rem;
  width: 100vw;
  min-width: 35rem;
  position: fixed;
  overflow: scroll;

  right: 0;
  @include m.flexCenter(column);
  justify-content: space-between;
  gap: 3rem;
  transition: all 0.5s ease-in-out;
  background-color: v.$color-neutral-dark;
  z-index: 100;

  @include m.mediaDesktop {
    display: none;
  }

  & ul {
    padding-bottom: 4rem;
    width: 100%;
    height: 100%;
    @include m.flexCenter(column);
    justify-content: space-evenly;
  }

  & li {
    text-decoration: none;
    list-style: none;
    padding: 2rem;
    background-color: v.$color-primary;
    width: 80%;
    text-align: center;
  }
}

.menu {
  // right: 0;
  transform: translateX(0);
}

.menuHidden {
  // width: 0;
  transform: translateX(100%);
  transition: all 0.5s ease-in-out;
}

.link {
  margin-top: 3rem;
}

.link a {
  // margin-left: 3em;
  font-size: 2rem;
  color: v.$grey-color-1;
  font-weight: 600;
  letter-spacing: 0.3rem;
}

.link:nth-child(5) {
  margin-bottom: 3rem;
}
