$color-primary: #0092ca;
$color-primary-dark: #0092ca;
$grey-color-0: #f8f9fa;
$grey-color-1: #f1f3f5;
$grey-color-2: #e9ecef;
$grey-color-3: #dee2e6;
$grey-color-4: #ced4da;
$grey-color-5: #adb5bd;
$grey-color-6: #868e96;
$grey-color-7: #495057;
$grey-color-8: #343a40;
$grey-color-9: #212529;

$color-primary-light: #ffffff;

$color-secondary: #fff602;

$color-neutral: #d4d4d4;

$transparent: #252a3431;
$transparent-less: #252a34e0;

$box-shadow-light: 5px 5px 24px -2px #ffffff3f;
$box-shadow-dark: 5px 5px 24px -2px #000000b7;

$text-color-light: $grey-color-4;
$text-color-mid: $grey-color-6;
$text-color-mid-dark: $grey-color-8;
$text-color-dark: $grey-color-9;

$color-neutral-dark: $grey-color-0;

$color-footer: $color-primary;
$color-main: $color-primary;
$color-navLinks: $color-primary-light;
$color-blogTitle: $grey-color-8;

$hamburger: $color-secondary;
