@use "../../styles/abstracts/variables" as v;
@use "../../styles/abstracts/mixins" as m;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.75);
}

.closeTop,
.closeBottom {
  position: absolute;
  top: 7rem;
  left: 50%;
  width: 8rem;
  border-radius: 10px;
  height: 3px;
  background-color: v.$grey-color-0;
}

.closeTop {
  transform: rotate(45deg);
}
.closeBottom {
  transform: rotate(-45deg);
}

.modal {
  position: fixed;
  height: 30rem;
  top: calc(50% - 20rem);
  left: 5%;
  width: 90%;
  background-color: v.$grey-color-3;
  border-radius: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  animation: slide-down 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .modal {
    width: 70rem;
    height: 40rem;
    left: calc(50% - 35rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
