@use "../../styles/abstracts/mixins" as m;
@use "../../styles/abstracts/variables" as v;

.footer {
  background-color: v.$color-footer;
  width: 100%;
  padding: 0 10%;
  display: grid;
  grid-template-rows: 2fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  border-top: 1px solid v.$grey-color-8;
}

.nav {
  line-height: 2;
  margin: 1em auto;
}

.nav li {
  list-style: none;
}

.nav a {
  font-weight: 600;
  color: v.$color-navLinks;
  letter-spacing: 0.2rem;
}

.legal {
  @include m.flexCenter(column);
  @include m.mediaDesktop() {
    align-items: flex-start;
  }

  gap: 1rem;
  font-size: 1.5rem;
  & a {
    color: v.$grey-color-4;
    &:hover {
      color: v.$color-secondary;
    }
  }
}

.nav a:hover,
.nav a:focus {
  color: v.$color-secondary;
}

.copyright {
  margin: 1em;
  align-self: center;
  justify-self: center;
  color: v.$color-primary-light;
}

.logo {
  grid-row: 2/3;
  aspect-ratio: 2/1;
  margin: auto;
  width: 100%;
  max-height: 30rem;
  min-height: 20rem;
  max-width: 32rem;
  position: relative;
  @include m.flexCenter();
}

@media (min-width: 800px) {
  .footer {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 4fr 1fr;
    height: 30rem;
  }
  .copyright {
    grid-row: 2/3;
    grid-column: 2/3;
  }

  .logo {
    grid-row: 1/2;
  }
}
